import React, { useState } from "react";
import axios from "axios";
import "./EditDishForm.css";

const EditDishForm = ({ dish, onClose }) => {
  const [dishName, setDishName] = useState(dish.dishName);
  const [dishDescription, setDishDescription] = useState(dish.dishDescription);
  const [dishPrice, setDishPrice] = useState(dish.dishPrice);
  const [dishPicture, setDishPicture] = useState(dish.dishPicture);
  const [updating, setUpdating] = useState(false);
  const [newDishPicture, setNewDishPicture] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setUpdating(true);
    const formData = new FormData();
    formData.append("dishId", dish.id);
    formData.append("dishName", dishName);
    formData.append("dishDescription", dishDescription);
    formData.append("dishPrice", dishPrice);

    if (newDishPicture !== null) {
      formData.append("dishPicture", newDishPicture);
    } else {
      formData.append("dishPicture", dishPicture);
    }

    axios
      .post("https://www.hungrybudget.com/endpoints/updateDish.php", formData)
      .then((response) => {
        console.log(response.data, "response from updateDish");
        setTimeout(() => {
          setUpdating(false);
          onClose();
        });
      })
      .catch((error) => {
        console.log(error);
        setUpdating(false);
      });
  };

  const handleDishPictureChange = (event) => {
    const file = event.target.files[0];
    setDishPicture(URL.createObjectURL(file));
    setNewDishPicture(file);
  };

  return (
    <div className='form-overlay'>
      <div className='back' onClick={onClose}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          className='back-arrow'>
          <path
            fill='#2c3e50'
            d='M5.7 12l8.3-8.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7.6 7.6 7.6 7.6c.4.4.4 1 0 1.4-.2.2-.5.3-.7.3s-.5-.1-.7-.3l-8.3-8.3c-.4-.4-.4-1 0-1.4z'
          />
        </svg>
        <span className='back-text'>Back to Table</span>
      </div>
      {updating && <div className='updating-message'>Updating...</div>}
      <div className='form-container-update-dish'>
        <form onSubmit={handleSubmit}>
          <div className='image-preview-container'>
            <img
              className='image-preview'
              src={`https://www.hungrybudget.com/images/${dish.dishPicture}`}
              alt={dish.dishName}
            />
          </div>
          <div className='form-label image-label'>
            <label>Dish Picture</label>
            <input
              type='file'
              accept='image/*'
              onChange={handleDishPictureChange}
            />
          </div>
          <label className='form-label'>
            Dish Name
            <input
              type='text'
              className='form-input'
              value={dishName}
              onChange={(event) => setDishName(event.target.value)}
              required
            />
          </label>
          <label className='form-label'>
            Dish Description
            <textarea
              className='form-input'
              value={dishDescription}
              onChange={(event) => setDishDescription(event.target.value)}
              required></textarea>
          </label>
          <label className='form-label'>
            Dish Price
            <input
              type='number'
              className='form-input'
              value={dishPrice}
              onChange={(event) => {
                const price = event.target.value;
                if (price >= 1) {
                  setDishPrice(price);
                }
              }}
              required
            />
          </label>
          <div className='buttons'>
            <button type='submit' className='submit-btn'>
              Save
            </button>
            <button type='button' className='cancel-btn' onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditDishForm;
