import React, { useState } from "react";
import axios from "axios";
import "./BusinessDetailsForm.css";

function BusinessDetailsForm({
  restaurantId,
  businessDetails,
  handleCancelClick,
  exitEdit,
}) {
  const [formData, setFormData] = useState({
    restaurantName: businessDetails.restaurantName,
    restaurantAddress: businessDetails.restaurantAddress,
    restaurantCity: businessDetails.restaurantCity,
    restaurantPhone: businessDetails.restaurantPhone,
    restaurantMenu: businessDetails.restaurantMenu,
  });

  const [isUpdating, setIsUpdating] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const endpoint =
      "https://www.hungrybudget.com/endpoints/updateBusinessDetails.php";
    const form = new FormData();
    form.append("restaurantId", restaurantId);
    form.append("restaurantName", formData.restaurantName);
    form.append("restaurantAddress", formData.restaurantAddress);
    form.append("restaurantCity", formData.restaurantCity);
    form.append("restaurantPhone", formData.restaurantPhone);
    form.append("restaurantMenu", formData.restaurantMenu);
    console.log(form);
    setIsUpdating(true);
    setIsDisabled(true);
    axios
      .post(endpoint, form)
      .then((response) => {
        console.log(response.data);
        setTimeout(() => {
          setIsUpdating(false);
          exitEdit();
        }, 4000);
      })
      .catch((error) => {
        console.error(error);
      });
    setTimeout(() => {
      setIsDisabled(false);
    }, 4000);
  };

  return (
    <div className='form-container'>
      {isUpdating && <div className='updating-message'>Updating...</div>}
      <form onSubmit={handleSubmit}>
        <label htmlFor='restaurantName'>Restaurant Name:</label>
        <input
          type='text'
          id='restaurantName'
          name='restaurantName'
          value={formData.restaurantName}
          onChange={handleInputChange}
          required
        />
        <label htmlFor='restaurantAddress'>Restaurant Address:</label>
        <input
          type='text'
          id='restaurantAddress'
          name='restaurantAddress'
          value={formData.restaurantAddress}
          onChange={handleInputChange}
          required
        />
        <label htmlFor='restaurantCity'>Restaurant City:</label>
        <input
          type='text'
          id='restaurantCity'
          name='restaurantCity'
          value={formData.restaurantCity}
          onChange={handleInputChange}
          required
        />
        <label htmlFor='restaurantPhone'>Restaurant Phone:</label>
        <input
          type='text'
          id='restaurantPhone'
          name='restaurantPhone'
          value={formData.restaurantPhone}
          onChange={handleInputChange}
          required
        />
        <label htmlFor='restaurantMenu'>Restaurant Menu:</label>
        <input
          type='text'
          id='restaurantMenu'
          name='restaurantMenu'
          value={formData.restaurantMenu}
          onChange={handleInputChange}
        />
        <button type='submit' disabled={isDisabled}>
          Save Changes
        </button>
      </form>
      <div className='back' onClick={handleCancelClick}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          fill='#2c3e50'
          width='24px'
          height='24px'
          className='back-arrow'>
          <path d='M0 0h24v24H0V0z' fill='none' />
          <path d='M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z' />
        </svg>
        <span className='back-text'>Back to business details</span>
      </div>
    </div>
  );
}

export default BusinessDetailsForm;
