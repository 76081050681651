import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import "./TableAndTiles.css";

const TotalClicksTile = () => {
  const user = useSelector((state) => state.user);
  const restaurantId = user.id;
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(
        `https://www.hungrybudget.com/endpoints/totalClicks.php?restaurantId=${restaurantId}`
      )
      .then((response) => {
        setData(response);
      })
      .catch((error) => console.log(error));
  }, [restaurantId]);

  return (
    <div className='box'>
      <div className='title'>Total Clicks</div>
      <div className='data'>{data.data}</div>
    </div>
  );
};

export default TotalClicksTile;
