import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import BusinessDetailsForm from "./BusinessDetailsForm";
import "./BusinessDetails.css";

function BusinessDetails({ closeBusinessDetails }) {
  const user = useSelector((state) => state.user);
  const restaurantId = user.id;
  const [businessDetails, setBusinessDetails] = useState(null);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    async function fetchBusinessDetails() {
      try {
        const response = await axios.get(
          `https://www.hungrybudget.com/endpoints/businessDetails.php?restaurantId=${restaurantId}`
        );
        setBusinessDetails(response.data.data[0]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchBusinessDetails();
  }, [restaurantId]);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelClick = () => {
    setEditMode(false);
  };

  const exitEdit = () => {
    axios
      .get(
        `https://www.hungrybudget.com/endpoints/businessDetails.php?restaurantId=${restaurantId}`
      )
      .then((response) => {
        setBusinessDetails(response.data.data[0]);
        setEditMode(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className='business-details'>
      <div className='close-button' onClick={closeBusinessDetails}>
        X
      </div>
      {businessDetails ? (
        <div>
          <div className='edit-buttons'>
            {!editMode && (
              <button onClick={handleEditClick}>Edit Business Details</button>
            )}
            {editMode && <button onClick={handleCancelClick}>Cancel</button>}
          </div>
          {editMode ? (
            <BusinessDetailsForm
              restaurantId={restaurantId}
              businessDetails={businessDetails}
              handleCancelClick={handleCancelClick}
              exitEdit={exitEdit}
            />
          ) : (
            <div>
              <h2>{businessDetails.restaurantName}</h2>
              <p>Address: {businessDetails.restaurantAddress}</p>
              <p>City: {businessDetails.restaurantCity}</p>
              <p>Phone: {businessDetails.restaurantPhone}</p>
              <p>
                Menu:{" "}
                <a
                  target='_blank'
                  href={businessDetails.restaurantMenu}
                  rel='noreferrer'>
                  {businessDetails.restaurantMenu}
                </a>
              </p>
            </div>
          )}
        </div>
      ) : (
        <p>Loading business details...</p>
      )}
    </div>
  );
}

export default BusinessDetails;
