import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import "./TableAndTiles.css";

const MostClickedDishTile = () => {
  const user = useSelector((state) => state.user);
  const restaurantId = user.id;
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(
        `https://www.hungrybudget.com/endpoints/mostClickedItem.php?restaurantId=${restaurantId}`
      )
      .then((response) => {
        const data = response.data.data[0];
        setData(data);
      })
      .catch((error) => console.log(error));
  }, [restaurantId]);

  return (
    <div className='box'>
      <div className='title'>Most Clicked Dish</div>
      <div className='dish'>{data.dishName}</div>
      <div className='data'>{data.addressClicks}</div>
    </div>
  );
};

export default MostClickedDishTile;
