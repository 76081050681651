import React from "react";

function THead() {
  return (
    <thead>
      <tr>
        <th>ID</th>
        <th>Dish Name</th>
        <th>Description</th>
        <th>Price</th>
        <th>Views</th>
        <th>Clicks</th>
        <th>Live</th>
        <th>Image</th>
        <th>Edit</th>
        <th>Delete</th>
      </tr>
    </thead>
  );
}

export default THead;
