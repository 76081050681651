import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import image from "../icons/image.svg";
import edit from "../icons/edit.svg";
import trash from "../icons/trash.svg";
import MostClickedDishTile from "./Tiles/MostClickedDishTile";
import MostViewedDishTile from "./Tiles/MostViewedDishTile";
import TotalViewsTile from "./Tiles/TotalViewsTile";
import TotalClicksTile from "./Tiles/TotalClicksTile";
import NewDishForm from "./Header/NewDishForm";
import BusinessDetails from "./Header/BusinessDetails";
import THead from "./THead";
import "./Tiles/TableAndTiles.css";
import EditDishForm from "./EditDishForm";

const Table = () => {
  const user = useSelector((state) => state.user);
  const restaurantId = user.id;
  const [data, setData] = useState([]);
  const [restaruantName, setRestaruantName] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [showBusinessDetails, setShowBusinessDetails] = useState(false);
  const [expandedImage, setExpandedImage] = useState(false);
  const [editDish, setEditDish] = useState(null);
  const [showEditForm, setShowEditForm] = useState(false);
  const [deleteDisabled, setDeleteDisabled] = useState(false);

  useEffect(() => {
    axios
      .get(
        `https://www.hungrybudget.com/endpoints/homeTable.php?restaurantId=${restaurantId}`
      )
      .then((response) => {
        const data = response.data.data;
        setData(data);
        setRestaruantName(data[0].restaurantName);
      })
      .catch((error) => console.log(error));
  }, [restaurantId]);

  const backToTable = () => {
    axios
      .get(
        `https://www.hungrybudget.com/endpoints/homeTable.php?restaurantId=${restaurantId}`
      )
      .then((response) => {
        const data = response.data.data;
        setData(data);
        setRestaruantName(data[0].restaurantName);
        setShowForm(false);
      })
      .catch((error) => console.log(error));
    setTimeout(() => {
      setShowForm(false);
      setShowEditForm(false);
    }, 2000);
  };

  const deleteDish = (dishId, dishPicture) => {
    if (!deleteDisabled) {
      setDeleteDisabled(true);
      axios
        .get(
          `https://www.hungrybudget.com/endpoints/deleteDish.php?dishId=${dishId}&dishPicture=${dishPicture}`
        )
        .then((response) => {
          setTimeout(() => {
            axios
              .get(
                `https://www.hungrybudget.com/endpoints/homeTable.php?restaurantId=${restaurantId}`
              )
              .then((response) => {
                const data = response.data.data;
                setData(data);
                setRestaruantName(data[0].restaurantName);
              })
              .catch((error) => console.log(error));
          }, 1000);
        })
        .catch((error) => console.log(error));
      setTimeout(() => {
        setDeleteDisabled(false);
      }, 5000); // allow delete button to be clicked again after 5 seconds
    }
  };

  const ExpandedImage = ({ imageUrl, onClose }) => {
    return (
      <div className='expanded-image'>
        <img src={imageUrl} alt='expandedImage' />
        <div className='close-icon' onClick={onClose}>
          X
        </div>
      </div>
    );
  };

  return (
    <div>
      {showBusinessDetails ? (
        <BusinessDetails
          closeBusinessDetails={() => setShowBusinessDetails(false)}
        />
      ) : showForm ? (
        <NewDishForm backToTable={backToTable} />
      ) : (
        showEditForm && (
          <EditDishForm
            dish={editDish}
            onClose={() => {
              backToTable();
            }}
          />
        )
      )}
      {!showBusinessDetails && !showForm && !showEditForm && (
        <>
          <div className='header'>
            <div
              className='new-dish'
              onClick={() => setShowBusinessDetails(true)}>
              Business details
            </div>
            {restaruantName && <h1>Admin Panel {restaruantName}</h1>}
            <div className='new-dish' onClick={() => setShowForm(true)}>
              Add new Dish
            </div>
          </div>
          <div className='row'>
            <MostClickedDishTile />
            <TotalClicksTile />
            <MostViewedDishTile />
            <TotalViewsTile />
          </div>
          <div className='refresh-button-area'>
            <div className='refresh-button' onClick={backToTable}>
              Refresh Table
            </div>
          </div>
          <div className='table-container'>
            <table className='custom-table'>
              <THead />
              <tbody>
                {data.map((item) => {
                  return (
                    <React.Fragment key={item.id}>
                      <tr>
                        <td>{item.id}</td>
                        <td>{item.dishName}</td>
                        <td>{item.dishDescription}</td>
                        <td>{item.dishPrice}</td>
                        <td>{item.views}</td>
                        <td>{item.addressClicks}</td>
                        <td>
                          {item.isLive === "1" || item.isLive === 1
                            ? "Yes"
                            : "No"}
                        </td>
                        <td>
                          <div className='icon-box'>
                            <img
                              className='expand-icon'
                              src={image}
                              alt='showImage'
                              onClick={(e) => {
                                e.stopPropagation();
                                setExpandedImage(
                                  `https://www.hungrybudget.com/images/${item.dishPicture}`
                                );
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='icon-box'>
                            <img
                              className='expand-icon'
                              src={edit}
                              alt='showImage'
                              onClick={(e) => {
                                e.stopPropagation();
                                setEditDish(item);
                                setShowEditForm(true);
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='icon-box'>
                            <img
                              className='expand-icon'
                              src={trash}
                              alt='showImage'
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteDish(item.id, item.dishPicture);
                              }}
                            />
                            {deleteDisabled && (
                              <div className='delete-disabled-overlay'></div>
                            )}
                          </div>
                        </td>
                      </tr>
                      {expandedImage ===
                        `https://www.hungrybudget.com/images/${item.dishPicture}` && (
                        <tr>
                          <td colSpan='10'>
                            <div className='image-box'>
                              <ExpandedImage
                                imageUrl={expandedImage}
                                onClose={() => setExpandedImage(false)}
                              />
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default Table;
