import React from "react";
import Login from "./Login";
import Table from "./Table";
import { useSelector } from "react-redux";

const App = () => {
  const user = useSelector((state) => state.user);

  return (
    <div>
      {!user.id && <Login />}
      {user.id && <Table />}
    </div>
  );
};

export default App;
