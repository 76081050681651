import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import "./Form.css";
import back from "./back.svg";

const Form = ({ backToTable }) => {
  const user = useSelector((state) => state.user);
  const restaurantId = user.id;
  const [data, setData] = useState();
  const [dishName, setDishName] = useState("");
  const [dishPrice, setDishPrice] = useState("");
  const [dishType, setDishType] = useState("");
  const [dishDescription, setDishDescription] = useState("");
  const [dishCuisine, setDishCuisine] = useState("");
  const [dishPicture, setDishPicture] = useState(null);

  useEffect(() => {
    async function fetchBusinessDetails() {
      try {
        const response = await axios.get(
          `https://www.hungrybudget.com/endpoints/businessDetails.php?restaurantId=${restaurantId}`
        );
        setData(response.data.data[0]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchBusinessDetails();
  }, [restaurantId]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const endpoint = "https://www.hungrybudget.com/endpoints/newDish.php";
    const form = new FormData();
    form.append("restaurantId", restaurantId);
    form.append("restaurantName", data.restaurantName);
    form.append("restaurantAddress", data.restaurantAddress);
    form.append("restaurantCity", data.restaurantCity);
    form.append("restaurantPhone", data.restaurantPhone);
    form.append("restaurantMenu", data.restaurantMenu);
    form.append("dishName", dishName);
    form.append("dishPrice", dishPrice);
    form.append("dishType", dishType);
    form.append("dishDescription", dishDescription);
    form.append("dishCuisine", dishCuisine);
    form.append("dishPicture", dishPicture);

    axios
      .post(endpoint, form)
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      });
    setTimeout(() => {
      backToTable();
    }, 1000);
  };

  return (
    <div>
      <div className='back' onClick={backToTable}>
        <img className='back-arrow' src={back} alt='back' />
        <div className='back-text'>Back</div>
      </div>
      <form className='form-container' onSubmit={handleSubmit}>
        <label className='form-label'>
          Dish Name
          <input
            type='text'
            className='form-input'
            value={dishName}
            onChange={(event) => setDishName(event.target.value)}
            required
          />
        </label>
        <label className='form-label'>
          Dish Price
          <input
            type='number'
            className='form-input'
            value={dishPrice}
            onChange={(event) => {
              const price = event.target.value;
              if (price >= 1) {
                setDishPrice(price);
              }
            }}
            required
          />
        </label>

        <label className='form-label'>
          Dish Type
          <select
            className='form-input'
            value={dishType}
            onChange={(event) => setDishType(event.target.value)}
            required>
            <option value=''>Select Type</option>
            <option value='breakfast'>Breakfast</option>
            <option value='dinner'>Dinner</option>
            <option value='dessert'>Dessert</option>
          </select>
        </label>
        <label className='form-label'>
          Dish Description
          <textarea
            className='form-input'
            value={dishDescription}
            onChange={(event) =>
              setDishDescription(event.target.value)
            }></textarea>
        </label>
        <label className='form-label'>
          Dish Cuisine
          <select
            className='form-input'
            value={dishCuisine}
            onChange={(event) => setDishCuisine(event.target.value)}
            required>
            <option value=''>Select Cuisine</option>
            <option value='american'>American</option>
            <option value='spanish'>Spanish</option>
            <option value='italian'>Italian</option>
            <option value='chinese'>Chinese</option>
            <option value='japanese'>Japanese</option>
            <option value='indian'>Indian</option>
            <option value='french'>French</option>
            <option value='thai'>Thai</option>
            <option value='greek'>Greek</option>
            <option value='middle eastern'>Middle Eastern</option>
            <option value='korean'>Korean</option>
            <option value='vietnamese'>Vietnamese</option>
            <option value='caribbean'>Caribbean</option>
          </select>
        </label>

        <label className='form-label'>
          Dish Picture
          <input
            type='file'
            className='form-input'
            onChange={(event) => setDishPicture(event.target.files[0])}
            required
          />
        </label>
        <button className='form-button' type='submit'>
          Submit
        </button>
      </form>
    </div>
  );
};

export default Form;
